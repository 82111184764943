

*[data-tooltip] {
    position: absolute;
  }
  
  *[data-tooltip]::after {
    background: black;
    border-radius: 4px;
    bottom: 100%;
    color: white;
    content: attr(data-tooltip);
    display: block;
    font-size: 14px;
    left: 50%;
    max-width: 300px;
    height: 30px;
    max-height: 30px;
    line-height: 30px;
    opacity: 0;
    overflow: hidden;
    padding: 10px;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    transform: translate(-50%, -30%);
    transition: opacity .2s, transform .2s;
    white-space: pre;
    z-index: 105;
  }
  
  *[data-tooltip].tooltip-on::after {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  
  *[data-tooltip]:hover::after {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  
  
  *[data-tooltip]:focus::after {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  
  @keyframes highlight {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
  
  .highlight::after {
    display: block;
    width: 100vw;
    height: 100vh;
    content: '';
    line-height: 100vh;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    background: black;
    opacity: 0.5;
    animation: highlight 0.7s ease-in-out;
    transform: translate(0%, 0%);
  }
  
  .highlight {
    z-index: 106;
  }

input[readonly] {
  pointer-events: none;
  opacity: 0.5;
}

input[readonly] + label {
  pointer-events: none;
}