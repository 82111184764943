@import url('@aws-amplify/ui-react/styles.css');
@import url('@draft-js-plugins/image/lib/plugin.css');
@import url('@draft-js-plugins/focus/lib/plugin.css');
@import url('@draft-js-plugins/inline-toolbar/lib/plugin.css');
@import url('@draft-js-plugins/static-toolbar/lib/plugin.css');

#root {
  width: 100%;
  height: 100%;
}

[data-amplify-theme] {
  min-height: 100vh;
  width: 100%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes slide {
  0% { transform: translateX(-200px); }
  100% { transform: translateX(0px); }
}

.app-container {
  height: 100%;
  width: 100%;
  display: flex;
}

.main-content {
  height: 100%;
  width: calc(100% - 160px);
  overflow: visible;
}

.main-content-modal {
  height: 100%;
  width: 100%;
  overflow: auto;
  margin: auto;
}

@media screen and (max-width: 800px) {
  .main-content {
    padding-top: 60px;
    width: 100%;
  }
}

.DraftEditor-editorContainer img {
  max-width: 100%;
}

.DraftEditor-editorContainer figure {
  margin: 0 !important;
}
